<template>
<div class="meback">
    <div class="metop">
        <div class="metop-img-div">
            <div class="king-div">
                <img class="king-img" src="@/assets/img/me/icon_king.png"/>
                <img v-if="!data.img" class="head" src="@/assets/img/me/icon_head.png"/>
                <img v-if="data.img" class="head" :src="data.img"/>
            </div>
        </div>
        <div class="metop-name">
            <div class="metop-name-name">{{data.name}}</div>
            <div class="metop-name-lab">
                <img v-if="data.levelimg!=''" :src="data.levelimg"/>
            </div>
        </div>
        <div class="metop-set" @click="clickMe('set')">
            <img src="@/assets/img/me/icon_set.png"/>
        </div>
    </div>
    <div class="melevelbg" v-if="data.levelmap">
        <div class="melevelbg-name">{{data.levelname}}</div>
        <div class="melevelbg-sp"></div>
        <div class="melevelbg-desc">发展{{data.levelmap[data.levelup]?data.levelmap[data.levelup].nums_user:"3"}}名成员升级为{{data.levelmap[data.levelup]?data.levelmap[data.levelup].level_name:"黄金会员"}}</div>
        <div style="margin-left:auto;margin-top:auto;margin-bottom:auto;">
        <div class="melevelbg-btn" @click="clickMe('share')">去升级</div>
        </div>
    </div>
    <div class="memoney">
        <div class="memoney-item" @click="clickMe('income')">
            <div class="memoney-money">{{data.smodel.incomeMoney}}</div>
            <div class="memoney-name">总收入（元）</div>
        </div>
        <div class="memoney-sp"></div>
        <div class="memoney-item" @click="clickMe('cashout')">
            <div class="memoney-money">{{data.smodel.accountMoney}}</div>
            <div class="memoney-name">可提现（元）</div>
        </div>
        <div class="memoney-sp"></div>
        <div class="memoney-item">
            <div class="memoney-money">0</div>
            <div class="memoney-name">今日邀请</div>
        </div>
        <div class="memoney-sp"></div>
        <div class="memoney-item">
            <div class="memoney-money">0</div>
            <div class="memoney-name">今日团队</div>
        </div>
    </div>

    <div class="mecard">
        <div class="mecard-top">
            <div class="mecard-left">
                <div class="mecard-left-top">
                    <div class="f1">余额（元）</div>
                    <div class="timg">
                        <img src="@/assets/img/me/icon_eyes.png"/>
                    </div>
                </div>
                <div class="f2">
                    {{data.smodel.accountMoney}}
                </div>
            </div>
            <div class="mecard-right">
                <div @click="clickMe('cashout')" class="mecard-right-btn">
                    提现
                </div>
            </div>
        </div>
        <div class="mecard-income">
            <div class="mecard-income1">
                <div class="f1">本月收入（元）</div><div class="f2">{{data.smodel.incomeMoneyMonth}}</div>
            </div>
            <div class="mecard-income1" style="margin-left:auto">
                <div class="f1">本月提现（元）</div><div class="f2">{{data.smodel.cashOutMoneyMonth}}</div>
            </div>
        </div>
        <div class="mecard-income" style="margin-top: 0.8rem;">
            <div class="mecard-income1">
                <img class="mecard-income1-img" src="@/assets/img/me/icon_money.png"/>
                <div class="f1">我的收款账户</div>
            </div>
            <div class="mecard-income1" style="margin-left:auto">
                <div @click="clickMe('account')" class="f1">去设置 ></div>
            </div>
        </div>
    </div>

    <div class="mecon">
            <div @click="clickMe('customer')" class="meitem">
                <img src="@/assets/img/me/me_customer.png"/>
                <div>我的客户</div>
            </div>

            <div @click="clickMe('team')" class="meitem">
                <img src="@/assets/img/me/me_team.png"/>
                <div>我的团队</div>
            </div>
            <div @click="clickMe('search')" class="meitem">
                <img src="@/assets/img/me/me_search.png"/>
                <div>查询进度</div>
            </div>
            <div @click="clickMe('kf')" class="meitem">
                <img src="@/assets/img/me/me_kf.png"/>
                <div>专属客服</div>
            </div>
    </div>
    <div class="mecon-sp"></div>
    <div class="mecon">
            <div  @click="clickMe('shop')" class="meitem">
                <img src="@/assets/img/me/me_shop.png"/>
                <div>我的小店</div>
            </div>

            <div @click="clickMe('calc')" class="meitem">
                <img src="@/assets/img/me/me_cacl.png"/>
                <div>计算器</div>
            </div>
            <div @click="clickMe('share')" class="meitem">
                <img src="@/assets/img/me/me_star.png"/>
                <div>海报分享</div>
            </div>
            <div  @click="showJjr()" class="meitem">
                <img src="@/assets/img/me/me_hand.png"/>
                <div>经济人联盟</div>
            </div>
    </div>

    <div class="service">
		<div class="service-title2">客云云科技 · 让经纪人生活更美好</div>
		<div class="service-title3">移动办公 合规平台 专业服务</div>
	</div>
    <!----------菜单开始------------->
    <Menu :emodel="menuIndex"></Menu>
    <!----------菜单结束------------->

    <div style="height:100px;"></div>
    <KfCard ref="kfcomp"></KfCard>
    <JjrCard ref="jjrcomp"></JjrCard>
</div>
<van-dialog />
</template>
<script setup>
import {} from 'vant';
import {ref,reactive,computed,watch,markRaw,defineProps,defineExpose} from "vue"
import Menu from '../../components/Menu.vue'
import {useRoute,useRouter} from 'vue-router'
import api from '../../common/http.js';
import apin from '../../common/httpnoloading.js';
import {isNull,nullStr,showMessage} from '../../common/utils.js'

import KfCard from '../../components/KfCard.vue'
import JjrCard from '../../components/JjrCard.vue'
import { Dialog } from 'vant';

const kfcomp = ref(null)
const jjrcomp=ref(null)

function showJjr(){
  jjrcomp.value.showJjr()
}

function showKf(){
  kfcomp.value.showKf();
}


let data=reactive({
  smodel:{},
  kfShow:false,
  name:localStorage.getItem("name"),
  img:nullStr(localStorage.getItem("img")),
  level:nullStr(localStorage.getItem("level")),
  levelname:nullStr(localStorage.getItem("levelname")),
  levelimg:nullStr(localStorage.getItem("levelimg")),
  levelup:nullStr(localStorage.getItem("levelup")),
  levelmap:localStorage.getItem("levelmap")?JSON.parse(localStorage.getItem("levelmap")):""
})

let route=useRoute()
let router=useRouter()
let menuIndex={index:4}

function searchMeStatistics(){
    apin.post("/web/account/accountmoney",{}).then((res)=>{
        if(res.errcode=="0"){
            data.smodel=res.detail;
        }else{
            showMessage(res.errmsg);
        }
    })
}
searchMeStatistics();

function clickMe(type){
    
    if(type=="customer"){
        router.push({ path: '/customer' })
    }else if(type=="team"){
        router.push({ path: '/team' })
    }else if(type=="shop"){
        router.push({ path: '/shop' })
    }else if(type=="set"){
        router.push({ path: '/set' })
    }else if(type=="vip"){
        router.push({ path: '/vip' })
    }else if(type=="search"){
        router.push({ path: '/search' })
    }else if(type=="account"){
        router.push({ path: '/account' })
    }else if(type=="share"){
        router.push({ path: '/share' })
    }else if(type=="kf"){
        showKf();
    }else if(type=="calc"){
        router.push({ path: '/calc'} )
    }else if(type=="income"){
        router.push({ path: '/income'} )
    }else if(type=="cashout"){

        apin.post("/web/account/accountlist",{}).then((res)=>{
            if(res.errcode=="0"){
                if(res.rows && res.rows.length>0){
                    router.push({ path: '/cashout' })
                }else{
                    Dialog.confirm({
                        title: '消息提示',
                        confirmButtonText:"去设置",
                        message:
                            '请您先设置收款账号，再申请提现。',
                        })
                        .then(() => {
                            router.push({ path: '/account' })
                        })
                        .catch(() => {
                            // on cancel
                        });

                }
            }else{
                showMessage(res.errmsg);
            }
        })
        
    }
}

</script>
<style scoped lang="scss">
.pop-city{
  background: #fff;
  position: fixed;
  bottom: 0px;
  left: 0.5rem;
  right: 0.5rem;
  z-index: 99999;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  .pop-close{
    padding-left: 0.5rem;
    font-size:1.8rem;
  }
  .pop-kf{
    display: flex;
    .pop-ewm{
        flex:1;
        margin:2rem;
        img{
            width:100%;
        }
    }
    .pop-kf-desc{
        flex:1;
        text-align: right;
        margin:2rem;
        img{
            width:4rem;
        }
        .sp{
            height:1px;
            margin-top:1rem;
            margin-bottom: 1rem;
            background: #ebebeb;
        }
    }
  }

}
.service{
  .service-title2{
    text-align: center;
    color: #848E9D;
    line-height: 2rem;
    align-items: center;
    font-weight: 500;
    margin-left: 1rem;
    font-size: 1.1rem;
    margin-top: 4rem;
  }
  .service-title3{
    text-align: center;
    color: #949999;
    line-height: 2rem;
    align-items: center;
    margin-left: 1rem;
    font-size: 0.9rem;
    margin-bottom: 2rem;
  }
}

.mecon-sp{
    margin:1rem;
    height:1px;
    background: #ebebeb;
}
.mecon{
    margin:1rem;
    display: flex;
    .meitem{
        padding:1rem;
        flex:1;
        text-align: center;
        img{
            width:4rem;
        }
        div{
            margin-top:0.3rem;
            font-size:0.8rem;
            white-space:nowrap; 
        }
    }
}
.mecard{
    margin:1rem;
    padding:1rem;
    background: url('../../assets/img/me/card_bg.png');
    background-size: 100% 100%;
    color:#fff;
    .f1{
        margin-top:0.2rem;
        font-size:0.8rem;
    }
    .f2{
        font-size:1.2rem;
        font-weight: bold;
    }
    .mecard-income{
        display: flex;
        .mecard-income1{
            display: flex;
            .mecard-income1-img{
                margin-top: 0.3rem;
                margin-right:0.5rem;
                width:1rem;
                height:1rem;
            }
        }
    }
    .mecard-top{
        display: flex;
        
        .mecard-left{
            .mecard-left-top{
                margin-left: 0.3rem;
                display:flex;
                img{
                    width:1.2rem;
                }
            }
        }
        .mecard-right{
            margin-left: auto;
            .mecard-right-btn{
                color:#fa6c04;
                background: #fff;
                border-radius: 1rem;
                padding:0.2rem;
                padding-left: 1.5rem;
                padding-right: 1.5rem;
            }
        }
    }
}
.memoney{
    background: #fff;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0.5rem;
    margin:1rem;
    display: flex;
    .memoney-sp{
        width: 1px;
        height:2rem;
        background: #ebebeb;
        margin:auto;
    }
    .memoney-item{
        flex:1;
        .memoney-money{
            font-size: 1.2rem;
            font-weight: bold;
            text-align: center;
        }
        .memoney-name{
            text-align: center;
            font-size: 0.8rem;
            color:#b0b2b1;
            margin-top:0.3rem;
        }
    }
}
.melevelbg{
    margin:1rem;
    background: url('../../assets/img/me/me_level_bg.png');
    background-size: 100% 100%;
    padding:0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    .melevelbg-name{
        font-size: 1rem;
        color:#fde0a0;
        font-weight: bold;
        white-space:nowrap; 
    }
    .melevelbg-sp{
        margin:0.5rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        background:#fde0a0;
        width:1px;
    }
    .melevelbg-desc{
        font-size: 0.8rem;
        color:#fde0a0;
        margin-top:auto;
        margin-bottom:auto;
        white-space:nowrap; 
    }
    .melevelbg-btn{
        background:#fde0a0;
        border-radius: 0.5rem;
        font-size: 0.9rem;
        margin-top:auto;
        margin-bottom:auto;
        padding-left: 0.5rem;
        padding-right: 0.5rem;

    }
}
.metop{
    margin: 1rem;
    display: flex;
    .metop-img-div{
        position: relative;
        width:3rem;
        // border: 1px solid #fff;
        height: 3rem;
        border-radius: 50%;
        .king-div{
            position:absolute;
            margin-left: 2rem;
            margin-top: -0.5rem;
            .king-img{
                width:1rem;
            }
            .head{
                width: 3rem;
                height: 3rem;
                border-radius: 50%;
                margin-left: -2rem;
                margin-top: -0.8rem;
            }
        }
    }
    .metop-name{
        margin-left: 1rem;
        .metop-name-name{

        }
        .metop-name-lab{
            img{
                width:5rem;
            }
        }
    }
    .metop-set{
        margin-left:auto;
        img{
            width:1.5rem;
        }
    }
}
.meback{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: url('../../assets/img/me/me.png');
    background-size: 100% 100%;
    max-width: 30rem;
    overflow-y: auto;
}
</style>